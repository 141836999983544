import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {delay, map} from 'rxjs/operators';

@Injectable()
export class DataService {

  constructor(private http: HttpClient) {}

  loadWorkshops(): Observable<any> {
    const url = `${environment.API_URL}workshop?appId=${environment.APP_ID}`;
    return this.http.get(url);
  }

  searchWorkshops(term: string): Observable<any> {
    if (term.length > 0) {
      const url = `${environment.API_URL}workshop/search?term=${term}`;
      return this.http.get(url);
    } else {
      return of(null);
    }
  }

  loadWorkshopBySlug(slug: string): Observable<any> {
    const url = `${environment.API_URL}workshop/slug/${slug}`;
    return this.http.get(url);
  }

  loadPackages(): Observable<any> {
    const packages = [
      {
        id: 1,
        category: 'Utvendig',
        title: 'Basic utvendig',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin at nulla quis urna euismod gravida. Quisque et pharetra nisi, in hendrerit leo. Integer hendrerit leo et efficitur varius. In vel hendrerit metus. Suspendisse potenti. Duis pellentesque, tellus et euismod ultricies, justo massa tristique magna, et gravida ante velit eu lorem. Curabitur vitae vestibulum sapien.',
        price: 2000,
        time: 120,
      },
      {
        id: 2,
        category: 'Utvendig',
        title: 'Premium utvendig',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin at nulla quis urna euismod gravida. Quisque et pharetra nisi, in hendrerit leo. Integer hendrerit leo et efficitur varius. In vel hendrerit metus. Suspendisse potenti. Duis pellentesque, tellus et euismod ultricies, justo massa tristique magna, et gravida ante velit eu lorem. Curabitur vitae vestibulum sapien.',
        price: 2000,
        time: 120,
      },
      {
        id: 3,
        category: 'Innvendig',
        title: 'Basic innvendig',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin at nulla quis urna euismod gravida. Quisque et pharetra nisi, in hendrerit leo. Integer hendrerit leo et efficitur varius. In vel hendrerit metus. Suspendisse potenti. Duis pellentesque, tellus et euismod ultricies, justo massa tristique magna, et gravida ante velit eu lorem. Curabitur vitae vestibulum sapien.',
        price: 2000,
        time: 120,
      },
      {
        id: 4,
        category: 'Innvendig',
        title: 'Premium innvendig',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin at nulla quis urna euismod gravida. Quisque et pharetra nisi, in hendrerit leo. Integer hendrerit leo et efficitur varius. In vel hendrerit metus. Suspendisse potenti. Duis pellentesque, tellus et euismod ultricies, justo massa tristique magna, et gravida ante velit eu lorem. Curabitur vitae vestibulum sapien.',
        price: 2000,
        time: 120,
      },
      {
        id: 5,
        category: 'Tilleggstjenester',
        title: 'Glassbeskyttelse',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin at nulla quis urna euismod gravida. Quisque et pharetra nisi, in hendrerit leo. Integer hendrerit leo et efficitur varius. In vel hendrerit metus.',
        price: 2000,
        time: 120,
      },
      {
        id: 6,
        category: 'Tilleggstjenester',
        title: 'Flekking av steinsprut',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin at nulla quis urna euismod gravida. Quisque et pharetra nisi, in hendrerit leo. Integer hendrerit leo et efficitur varius. In vel hendrerit metus.',
        price: 2000,
        time: 120,
      },
      {
        id: 7,
        category: 'Tilleggstjenester',
        title: 'Vannsliping av riper',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin at nulla quis urna euismod gravida. Quisque et pharetra nisi, in hendrerit leo. Integer hendrerit leo et efficitur varius. In vel hendrerit metus.',
        price: 2000,
        time: 120,
      },
      {
        id: 8,
        category: 'Tilleggstjenester',
        title: 'Luktfjerning',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin at nulla quis urna euismod gravida. Quisque et pharetra nisi, in hendrerit leo. Integer hendrerit leo et efficitur varius. In vel hendrerit metus.',
        price: 2000,
        time: 120,
      }
    ];

    return of(packages).pipe(delay(250));
  }

  loadVehicleData(regno: string): Observable<any> {
    const url = `${environment.API_URL}vehicle/${regno}`;
    return this.http.get(url);

    // return of({"akseltrykkBak":"1070","akseltrykkForan":"1000","antAksler":"2","antAkslerDrift":"2","bredde":"173","bruktimport":"0","co2Utslipp":"0170","drivendeHjul":"","drivstoff":"2","EUvariant":"SEATDX11","EUversjon":"FM6QM62Y006O0GE","egenvekt":"1400","euHovednummer":"e11*95/54*0066*22","euronorm":"","fabPartFilter":"1","farge":"SVART","fjernet":"0","girkasse":"MANUELL","gyldigFraUnr":"TMB???1U?????????","identitetAnm":"","kjennemerke":"BP76422","kjoretoygruppe":"101","lengde":"451","lngdTilhKobl":"0","maksSporvBak":"1496","maksSporvForan":"1520","maxBelTilhFeste":"65","maxTaklast":"75","merkeNavn":"SKODA","merkekode":"5150","minHastBak":"","minHastForan":"T","minInnpressBak":"38","minInnpressForan":"38","minLIbak":"87","minLIforan":"87","modellbetegnelse":"OCTAVIA","motormerking":"ATD","motorytelse":"74,00","nestePKK":"2020-02-29","omdreininger":"3000","rammeKarosseri":"STASJONSVOGN","regAAr":"2002","regFgang":"1209","regFoerstegNorge":"2002-12-09","sistPKKgodkj":"2017-10-25","sisteRegDato":"2014-07-21","sitteplasser":"5","slagvolum":"1896","standStoy":"78","status":0,"stdDekkBak":"195/65R15","stdDekkForan":"195/65R15","stdFelgBak":"6JX15 H2","stdFelgForan":"6JX15 H2","tilhVktMbrems":"1500","tilhVktUbrems":"500","totalvekt":"1950","typebetegnelse":"1U","typegodkjenningsnr":"55200172002","understellsnummer":"TMBKS21U638684039","vognkortAnm":"EGENVEKT OG NYTTELAST ER VEILEDENDE-AVHENGIG AV UTSTYRSNIVÅ","vogntogvekt":"3450","ytelseBenevn":"1"});
  }

  loadPerson(phone: string): Observable<any> {
    const url = `https://live.intouch.no/tk/search.php?qry=${phone}&from=1&to=27&format=json&charset=UTF-8&username=cars&password=tkb28`;
    return this.http.get(url)
      .pipe(
        map((response: any) => this.convertToArray(response.result))
      );

    // return of({
    //     "qry":"94171848",
    //     "result":{
    //       "hitLinesBeforeFilter":1,
    //       "userID":299228,
    //       "1":{"listing":{
    //           "table":"listing",
    //           "id":"5611483",
    //           "duplicates":
    //             [
    //               {
    //                 "table":"listing",
    //                 "id":"5611483:0",
    //                 "idlinje":"E1ROVIS",
    //                 "tlfnr":"94171848",
    //                 "etternavn":"Wilberg",
    //                 "fornavn":"Elin Sunde",
    //                 "veinavn":"Grobråtenveien",
    //                 "husnr":"58",
    //                 "postnr":"1385",
    //                 "virkkode":"P",
    //                 "apparattype":"M",
    //                 "telco":"IC",
    //                 "kilde":"E",
    //                 "bkdata":"F",
    //                 "prioritet":"0",
    //                 "fodselsdato":"1984-11-04",
    //                 "kommunenr":"220",
    //                 "poststed":"Asker",
    //                 "kommune":"Asker",
    //                 "fylke":"Akershus",
    //                 "landsdel":"Ø"
    //               }
    //             ]
    //         }
    //       },
    //       "dummy":null
    //     }
    //   }
    // ).pipe(
    //   map((response: any) => this.convertToArray(response.result))
    // );
  }

  sendOrder(order: any): Promise<any> {
    // const url = `${environment.API_URL}web-order`;
    // return this.http.post(url, order).toPromise();

    return of().toPromise();
  }


  private convertToArray(data) {
    const array = [];

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const object = data[key];
        if (object && object.listing) {
          const listing = this.removePhoneDuplicates(object);
          array.push(listing);
        }
      }
    }

    return array;
  }

  private removePhoneDuplicates(data: any) {
    let result = {};
    const duplicates = data['listing']['duplicates'];

    for (let i = 0; i < duplicates.length; i++) {
      const duplicate = duplicates[i];
      if (duplicate['etternavn']) {
        // Split Firstname into first and middle
        if (duplicate['fornavn']) {
          const names = duplicate['fornavn'].split(' ');
          if (names.length > 1) {
            duplicate['fornavn'] = names[0];
            duplicate['mellomnavn'] = names[1];
          }
        }

        result = duplicate;
        break;
      }
    }

    return result;
  }

}
