import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {CoreModule} from './core/core.module';
import {HttpClientModule} from '@angular/common/http';
import {AgmCoreModule} from '@agm/core';
import {environment} from '../environments/environment';
import { registerLocaleData } from '@angular/common';
import locale from '@angular/common/locales/nb';
registerLocaleData(locale, 'nb');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    AgmCoreModule.forRoot({apiKey: environment.GOOGLE_MAPS_KEY})
  ],
  providers: [{
    provide: LOCALE_ID,
    useValue: 'nb'
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
