import {NgModule} from '@angular/core';
import {DataService} from './data.service';
import {StoreService} from './store.service';

@NgModule({
  providers: [
    DataService,
    StoreService
  ]
})
export class CoreModule {}
