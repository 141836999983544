import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable()
export class StoreService {
  initialState = {
    items: [],
    total: 0,
    count: 0,
  };
  cartState = this.initialState;
  cart$: BehaviorSubject<any> = new BehaviorSubject(this.cartState);
  emitter$: Subject<void> = new Subject<void>();

  addToCart(p: any): void {
    const index = this.cartState.items.findIndex((sP) => sP.id === p.id);

    if (index === -1) {
      this.cartState.items = [].concat(...this.cartState.items, Object.assign({}, p, {quantity: 1}));
    } else {
      this.cartState.items = this.cartState.items.map((sP) => {
        if (sP.id === p.id) {
          return Object.assign({}, sP, {quantity: sP.quantity + 1});
        }

        return sP;
      });
    }


    this.updateCart();
  }

  increaseItemCount(p: any): void {
    this.cartState.items = this.cartState.items.map((sP) => {
      if (sP.id === p.id) {
        return Object.assign({}, sP, {quantity: sP.quantity + 1});
      }

      return sP;
    });

    this.updateCart();
  }

  decreaseItemCount(p: any): void {
    this.cartState.items = this.cartState.items.map((sP) => {
      if (sP.id === p.id) {
        return Object.assign({}, sP, {
          quantity: (sP.quantity !== 1) ? sP.quantity - 1 : 1
        });
      }

      return sP;
    });

    this.updateCart();
  }

  removeItem(p: any): void {
    const index = this.cartState.items.findIndex((sP) => sP.id === p.id);

    if (index !== -1) {
      this.cartState.items.splice(index, 1);
    }

    this.updateCart();
  }

  clearCart(): void {
    this.cartState.items = [];
    this.updateCart();
  }

  private updateCart(): void {
    let total = 0;
    let count = 0;

    this.cartState.items.map((item) => {
      total += item.price * item.quantity;
      count = count + item.quantity;
    });

    this.cartState = Object.assign({}, this.cartState, {
      items: this.cartState.items,
      count,
      total,
    });

    this.cart$.next(this.cartState);
  }
}
